// connection types
export const SMS = 'sms';
export const PHONE = 'phone';
export const EMAIL = 'email';
export const NONE = 'none';

// urls
export const FORGOT_PASSWORD_URL = `/forgot-password`;
export const FORGOT_USERNAME_URL = `/forgot-username`;
export const PASSWORD_RESET_URL = `/password-reset`;
export const SIGN_IN_CHALLENGE_URL = `/sign-in-challenge`;
export const SIGN_IN_URL = `/sign-in`;
export const PASSWORD_RECOVERY_URL = `/password-recovery`;
export const BLOCKED_ACCOUNT_URL = `/blocked-account`;
export const ACCOUNT_RECOVERY_URL = `/account-recovery`;

// user statuses
export const LOCKED_STATUS = 'Locked';
