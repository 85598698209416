export interface CustomParams {
  [key: string]: string;
}

// eslint-disable-next-line import/prefer-default-export
export const parseCustomParams = (
  url: string,
  params: CustomParams = {}
): string => {
  let newUrl = url;
  if (Object.keys(params).length > 0) {
    const startSeparator = newUrl.includes('?') ? '&' : '?';
    Object.keys(params).map((key, index) => {
      const separator = index === 0 ? startSeparator : '&';
      newUrl += `${separator}${key}=${params[key]}`;
    });
  }

  return newUrl;
};

export const htmlDecode = (input: string) => {
  const doc = new DOMParser().parseFromString(input, 'text/html');
  return doc.documentElement.textContent;
};

export const getCustomParams = () => {
  let result = {};
  const paramString = window.location.search.split('?')[1];

  if (paramString) {
    const decodeParamString = `?${htmlDecode(paramString)}`;
    const urlParams = new URLSearchParams(decodeParamString);
    const queries = Array.from(urlParams.entries());

    result = queries.reduce(
      (obj, [key, value]) => ({ ...obj, [key]: value }),
      {}
    );
  }

  return result;
};
